<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Contracts</h4>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm" class="row">
            <div class="col-sm-6 col-md-6 col-lg-6 col-12 py-0 my-0">
              <v-autocomplete
                v-model="formData.client_id"
                label="Client"
                item-text="text"
                item-value="index"
                :items="serverData.clients"
                clearable
                dense
                outlined
              ></v-autocomplete>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-12 py-0 my-0">
              <v-text-field
                v-model="formData.contract_no"
                label="Contract No"
                clearable
                outlined
                dense
                :error-messages="contract_noErrors"
                @input="$v.formData.contract_no.$touch()"
                @blur="$v.formData.contract_no.$touch()"
              ></v-text-field>
            </div>
            <div class="col-sm-6 col-12 py-0 my-0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.from"
                    label="From"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :error-messages="fromErrors"
                    @input="$v.formData.from.$touch()"
                    @blur="$v.formData.from.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.from"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-sm-6 col-12 py-0 my-0">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.to"
                    label="To"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :error-messages="toErrors"
                    @input="$v.formData.to.$touch()"
                    @blur="$v.formData.to.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.to"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-text-field
                v-model="formData.average_volume"
                label="Average volume"
                clearable
                outlined
                type="number"
                :min="0"
                dense
                @change="() => validateMinValue('average_volume', 0)"
                :error-messages="average_volumeErrors"
                @input="$v.formData.average_volume.$touch()"
                @blur="$v.formData.average_volume.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6 py-0 my-0">
              <v-select
                v-model="formData.average_volume_range"
                label="Average volume range"
                :items="serverData.average_volume_range_types"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
                :error-messages="average_volume_rangeErrors"
                @input="$v.formData.average_volume_range.$touch()"
                @blur="$v.formData.average_volume_range.$touch()"
              ></v-select>
            </div>
            <div class="col-12 py-0 my-0">
              <v-text-field
                v-model="formData.description"
                label="Description"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="col-12">
              <sku-datatable
                :setSku="setServices"
                :skus="formData.services"
                :services="serverData.services"
                :pageLoader="pageLoader"
                :contractId="contract_id"
              ></sku-datatable>
            </div>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import SkuDatatable from "@/own/components/finance/customers/serviceContainer/SkuDatatable";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      from: { required },
      to: { required },
      average_volume_range: { required },
      average_volume: { required },
      contract_no: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  components: { SkuDatatable },
  data: () => ({
    dialog: false,
    serverData: null,
    menu1: false,
    menu2: false,
    formData: {
      id: null,
      client_id: null,
      services: [],
      contract_no: null,
      from: null,
      to: null,
      average_volume: null,
      average_volume_range: null,
      description: null,
    },
  }),
  methods: {
    setServices(item) {
      this.formData.services = [...item];
    },
    destinationTypeUpdate() {
      this.formData.destination_value = null;
    },
    setContainerData(item) {
      // console.log("before ap", this.formData.clients);
      let copy = { ...this.formData };
      copy.clients = item;
      this.formData = copy;
      // console.log("after ap", this.formData.clients);
    },

    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/cod/contracts/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    validateData() {
      return true;
    },
    servicesConverter(formData, index, data) {
      Object.entries(formData).forEach((item) => {
        if (Array.isArray(item[1])) {
          item[1].forEach((it) => {
            if (it || it == "0") {
              data.append(`services[${index}][${item[0]}][]`, it);
            }
          });
        } else if (
          typeof item[1] === "object" &&
          !Array.isArray(item[1]) &&
          item[1] !== null
        ) {
          Object.entries(item[1]).forEach((el) => {
            if (
              el[1] &&
              (el[0] == "rates_file" || el[0] == "increments_file")
            ) {
              data.append(`services[${index}][${item[0]}][${el[0]}]`, el[1]);
            } else if (Array.isArray(el[1])) {
              el[1].forEach((subEl, i) => {
                if (
                  typeof subEl === "object" &&
                  !Array.isArray(subEl) &&
                  subEl !== null
                ) {
                  Object.entries(subEl).forEach((objEl) => {
                    if (objEl[1] || objEl[1] == "0") {
                      data.append(
                        `services[${index}][${item[0]}][${el[0]}][${i}][${objEl[0]}]`,
                        objEl[1]
                      );
                    }
                  });
                } else {
                  if (subEl || subEl == "0") {
                    data.append(
                      `services[${index}][${item[0]}][${el[0]}][]`,
                      subEl
                    );
                  }
                }
              });
            } else {
              if (el[1] || el[1] == "0") {
                data.append(`services[${index}][${item[0]}][${el[0]}]`, el[1]);
              }
            }
          });
        } else {
          if (item[1] || item[1] == "0")
            data.append(`services[${index}][${item[0]}]`, item[1]);
        }
      });
    },
    convertToFormData() {
      // let data = new FormData();
      // Object.entries(this.formData).forEach((item) => {
      //   if (Array.isArray(item[1])) {
      //     item[1].forEach((service, index) => {
      //       this.servicesConverter(service, index, data);
      //     });
      //   } else {
      //     data.append(item[0], item[1]);
      //   }
      // });
      // // for (let [key, value] of data) {
      // //   console.log(`${key}: ${value}`);
      // // }
      // return data;
      return this.formData;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.formData.services.length == 0) {
        Swal.fire({
          title: "Warning",
          text: `Services are required!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      if (this.validateData()) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        ApiService.post("/cod/contracts/update", data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Record has been successfully updated!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.resetEditForm();

            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      } else {
        return;
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.contract };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.contract).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
              // console.log(serverData[0], copy[serverData[0]]);
            }
          });
        });
      } else {
        this.formData = {
          id: null,
          client_id: null,
          services: [],
          contract_no: null,
          from: null,
          to: null,
          description: null,
          average_volume: null,
          average_volume_range: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    contract_id() {
      return this.formData.id;
    },
    fromErrors() {
      return this.handleFormValidation("from", this);
    },
    toErrors() {
      return this.handleFormValidation("to", this);
    },
    contract_noErrors() {
      return this.handleFormValidation("contract_no", this);
    },
    destination_type: function () {
      return this.formData.destination_type;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    average_volume_rangeErrors() {
      return this.handleFormValidation("average_volume_range", this);
    },
    average_volumeErrors() {
      return this.handleFormValidation("average_volume", this);
    },
    // nameErrors() {
    //   return this.handleFormValidation("name", this);
    // },
  },
};
</script>
