<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template> -->
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">
            {{ editType === "rate" ? "Rate" : "Increment" }}
          </h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <form class="pt-4">
            <v-text-field
              v-model="formData.from"
              label="From"
              clearable
              outlined
              type="number"
              :error-messages="fromErrors"
              @input="$v.formData.from.$touch()"
              @blur="$v.formData.from.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.to"
              label="To"
              clearable
              outlined
              type="number"
              :error-messages="toErrors"
              @input="$v.formData.to.$touch()"
              @blur="$v.formData.to.$touch()"
            ></v-text-field>
            <v-text-field
              v-if="editType !== 'rate'"
              v-model="formData.increment"
              label="Increment"
              clearable
              outlined
              type="number"
              :error-messages="incrementErrors"
              @input="$v.formData.increment.$touch()"
              @blur="$v.formData.increment.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.rate"
              label="Rate"
              clearable
              outlined
              type="number"
              :error-messages="rateErrors"
              @input="$v.formData.rate.$touch()"
              @blur="$v.formData.rate.$touch()"
            ></v-text-field>
            <v-select
              v-if="isCountry == 0 && countryId != null && serverData"
              v-model="formData.city"
              label="City"
              item-text="title"
              item-value="title"
              :items="serverData.cities"
              clearable
              outlined
            ></v-select>
            <!-- new city field in delivery type service if country is selected , not required -->
            <v-main class="text-center mt-4">
              <v-btn class="mr-4" color="primary" @click="submitEditForm">
                submit
              </v-btn>
              <v-btn @click="resetFormData"> clear </v-btn>
            </v-main>
          </form>
        </v-card-text>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import Swal from "sweetalert2";
export default {
  name: "EditRate",
  mixins: [validationMixin],
  validations: {
    formData: {
      from: { required },
      to: {
        required: requiredIf(function () {
          return this.editType == "rate";
        }),
      },
      increment: {
        required: requiredIf(function () {
          return this.editType !== "rate";
        }),
      },
      rate: { required },
    },
  },
  // props: ["item", "actionFunction"],
  props: {
    item: {},
    actionFunction: {
      type: Function,
    },
    editType: {
      type: String,
      default: "rate",
    },
    isCountry: {},
    countryId: {},
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    serverData: null,
    search: null,
    count: null,
    entries: [],
    formData: {
      from: null,
      to: "",
      increment: null,
      rate: null,
      city: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      if (this.isCountry != 0 || this.countryId == null) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { country: this.countryId };
      ApiService.post("/address/cities/search", data)
        .then((response) => {
          this.serverData = response.data;
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        // this.formData.system_code = this.items.map(sku =>
        //   sku.skuId == this.formData.sku_id ? sku.systemCode : null
        // )[0];
        // console.log("supermode is ", this.formData, this.items);
        this.actionFunction(
          { ...this.formData },
          this.item ? this.item : false
        );
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();
      this.serverData = null;
      if (this.item) {
        let copy = { ...this.item };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.item).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });
        });
      } else {
        this.formData = {
          from: null,
          to: "",
          increment: null,
          rate: null,
          city: null,
        };
      }
    },
    toggleModal() {
      this.resetFormData();
      if (!this.dialog) {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    fromErrors: function () {
      return this.handleFormValidation("from", this);
    },
    toErrors: function () {
      return this.handleFormValidation("to", this);
    },
    incrementErrors: function () {
      return this.handleFormValidation("increment", this);
    },
    rateErrors: function () {
      return this.handleFormValidation("rate", this);
    },
  },
};
</script>
